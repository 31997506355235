import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ButtonBig, ModalSmall, Spinner, htmlDecode } from '@gleerups/syntax4';
import { CopyUrl } from '@gleerups/share-components';
import { useAuth } from '@gleerups/auth';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LicenseInfo, ProductInfo } from './types';
import ActivationContent from './ActivationModal/ActivationContent';
import LicenseActivatedContent from './ActivationModal/LicenseActivatedContent';
import api from '../api/api';
import { useTypedSelector } from '../store/rootReducer';

import {
  getEducationalMaterialIdOrProductIdToActivate,
  closeLibraryModal,
  getEducationalMaterialIdOrProductIdToShare,
} from '../Products/productsReducer';
import { basename } from '../constants';
import { ButtonWrapper } from './sharedComponents';
import { RoutePaths } from '../routePaths';

const ActivatedInfoPlaceholder = styled.div`
  height: 10rem;
`;

const fetchAvailableLicenses = async (educationalMaterialIdOrProductId: string) => {
  const availableLicensesData = await api.fetchAvailableLicenses(educationalMaterialIdOrProductId);
  return availableLicensesData ? availableLicensesData.data : null;
};

const LibraryModal = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { educationalMaterialIdOrProductId: educationalMaterialIdOrProductIdActivatedFromUrl } =
    useParams<{
      educationalMaterialIdOrProductId: string;
    }>();
  const location = useLocation();
  const dispatch = useDispatch();

  const educationalMaterialIdOrProductIdToActivate = useTypedSelector(
    getEducationalMaterialIdOrProductIdToActivate
  );
  const shareModalEducationalMaterialIdOrProductId = useTypedSelector(
    getEducationalMaterialIdOrProductIdToShare
  );

  const [regularLicences, setRegularLicences] = useState<LicenseInfo[]>([]);
  const [activatedLicenseId, setActivatedLicenseId] = useState<string | null>(null);
  const [productInfo, setProductInfo] = useState<ProductInfo | null>(null);
  const [activationOngoing, setActivationOngoing] = useState<boolean>(false);
  const [activationFailed, setActivationFailed] = useState<boolean>(false);
  const educationalMaterialIdOrProductId =
    educationalMaterialIdOrProductIdToActivate ||
    shareModalEducationalMaterialIdOrProductId ||
    educationalMaterialIdOrProductIdActivatedFromUrl;

  useEffect(() => {
    if (user.isAuthenticated) {
      (async () => {
        const licensesForProductResults = await fetchAvailableLicenses(
          educationalMaterialIdOrProductId
        );
        const filteredLicenses = licensesForProductResults.filter((license: LicenseInfo) => {
          // Check if the user exists in the license's users object
          if (user.userId in license.users) {
            return false;
          }
          return true;
        });
        setRegularLicences(filteredLicenses);
      })();
    }
    try {
      (async () => {
        const productInfoResult = await api.fetchProduct(educationalMaterialIdOrProductId);
        setProductInfo(productInfoResult);
      })();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { response } = e;
      if (response && response.status === 500) {
        history.push(RoutePaths.HOME);
      }
    }
  }, [user.isAuthenticated]);

  // Effect to close modal when back button has been clicked in browser
  useEffect(() => {
    window.onpopstate = () => {
      onClose();
    };
  });

  const onClose = () => {
    const { pathname } = location;
    // handle close when arriving from direct link to modal
    if (educationalMaterialIdOrProductIdActivatedFromUrl) {
      if (pathname.includes(RoutePaths.ACTIVATE_LICENSE)) {
        history.push(RoutePaths.ACTIVATE_LICENSE);
      } else if (pathname.includes(RoutePaths.TRIAL)) {
        history.push(RoutePaths.TRIAL);
      } else {
        history.push(RoutePaths.HOME);
      }
    }
    dispatch(closeLibraryModal());
  };

  const { title, thumbnailSrc, introduction: productIntroduction = '' } = productInfo || {};
  const activatedIsTrial = activatedLicenseId === 'trial';
  const absoluteUrl = `${window.location.origin}${basename}/prova/${shareModalEducationalMaterialIdOrProductId}`;

  if (!productInfo) {
    return null;
  }

  return (
    <ModalSmall onClose={onClose} isDismissable heading={title || ''} imgSrc={thumbnailSrc}>
      {shareModalEducationalMaterialIdOrProductId && (
        <>
          <p>
            <strong>Tipsa en kollega om det här läromedlet.</strong> Kopiera länken och skicka den
            till en kollega som sedan får prova läromedlet gratis i 30 dagar.
          </p>
          <CopyUrl url={absoluteUrl} />
          <ButtonWrapper>
            <ButtonBig color="blue" onClick={onClose}>
              Stäng
            </ButtonBig>
          </ButtonWrapper>
        </>
      )}

      {(educationalMaterialIdOrProductIdToActivate ||
        educationalMaterialIdOrProductIdActivatedFromUrl) && (
        <>
          <p>{htmlDecode(productIntroduction)}</p>
          {activationOngoing && (
            <ActivatedInfoPlaceholder>
              <Spinner />
            </ActivatedInfoPlaceholder>
          )}
          {!activatedLicenseId && (!activationOngoing || activationFailed) && (
            <ActivationContent
              regularLicences={regularLicences}
              productInfo={productInfo}
              setActivatedLicenseId={setActivatedLicenseId}
              setActivationOngoing={setActivationOngoing}
              activationFailed={activationFailed}
              setActivationFailed={setActivationFailed}
              educationalMaterialIdOrProductId={educationalMaterialIdOrProductId}
            />
          )}
          {activatedLicenseId && (
            <LicenseActivatedContent
              onCloseButtonClick={onClose}
              productInfo={productInfo}
              activatedIsTrial={activatedIsTrial}
              endDate={
                activatedIsTrial
                  ? null
                  : regularLicences.filter((license) => license.id === activatedLicenseId)[0]?.ends
              }
            />
          )}
        </>
      )}
    </ModalSmall>
  );
};

export default LibraryModal;
