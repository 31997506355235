import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Breakpoint,
  TextButton,
  CardContent,
  CardTitle,
  Card,
  CardImage,
  Spacing,
  Color,
  BasicTooltip,
} from '@gleerups/syntax4';

import { COMPLEMENT } from '../../constants';
import styled, { css } from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import { useMedia } from 'react-use';

import { setActivateLicenseModalId } from '../productsReducer';
import { Product, TabType } from '../types';
import CardTagList from '../Card/CardTagList';
import LicenseStatus from '../licenseStatus';

type TeacherActionProductCardProps = {
  product: Product;
  tab: TabType;
};

const StyledCardTitle = styled(CardTitle)<{ isDisabled: boolean }>`
  > h5 {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        color: ${Color.grey500};
      `}
  }
  @media (${Breakpoint.smallMax}) {
    -webkit-line-clamp: 3;
  }
`;

const CardWrapper = styled(Card)`
  @media (${Breakpoint.smallMax}) {
    img {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
`;

const CardContentWrapper = styled(CardContent)`
  padding: 0.5rem;
`;

const TextButtonWrapper = styled(TextButton)`
  padding-left: ${Spacing.XS};
`;

const LicensActivated = styled.p`
  color: ${Color.fokusGrassGreen};
  font-size: 10px;
  margin-bottom: 0;
`;

const StyledCheckIcon = styled(CheckIcon)<{ largeScreen: boolean }>`
  color: ${Color.fokusGrassGreen};
  margin-right: ${({ largeScreen }) => (largeScreen ? 0 : Spacing.XS)};
`;

const TeacherActionProductCard = ({ product, tab }: TeacherActionProductCardProps) => {
  const { id: productId, title, thumbnailSrc, type: productType, educationalMaterialId } = product;
  const dispatch = useDispatch();

  const isComplement = productType === COMPLEMENT;

  const onClick = () => {
    dispatch(setActivateLicenseModalId(educationalMaterialId || productId));
  };
  const ariaLabel = `${tab === TabType.Activate ? 'aktivera' : 'prova'} ${product.title}`;
  const largeScreen = useMedia(`(${Breakpoint.mediumMin})`);
  const isProductActivated =
    product.license?.status === LicenseStatus.ACTIVE ||
    product.license?.status === LicenseStatus.EXPIRES_SOON;

  const ref = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div
      onMouseOver={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
      ref={ref}
    >
      <CardWrapper
        role="button"
        aria-label={isProductActivated ? `Licens för ${product.title} är aktiverad` : ariaLabel}
        onClick={onClick}
        disabled={isProductActivated}
      >
        <CardImage
          aria-hidden
          thumbnailSrc={thumbnailSrc}
          title={title}
          aspectRatio="47%" // according to CONX-760. aspectRatio 47% gives width 126px
          disabled={isProductActivated}
        >
          <CardTagList
            isComplement={isComplement}
            product={product}
            showCategoryTags={tab === TabType.Activate || tab === TabType.Trial}
          />
        </CardImage>
        <CardContentWrapper>
          <>
            <div>
              <StyledCardTitle aria-hidden title={product.title} isDisabled={isProductActivated} />
              {isProductActivated && !largeScreen && (
                <LicensActivated>Licens aktiverad</LicensActivated>
              )}
            </div>
            {!isProductActivated && (
              <TextButtonWrapper
                color="blue"
                tabIndex={-1}
                aria-hidden
                onClick={() => dispatch(setActivateLicenseModalId(product.id))}
              >
                {tab === TabType.Activate ? 'Aktivera' : 'Prova'}
              </TextButtonWrapper>
            )}

            {isProductActivated && <StyledCheckIcon largeScreen={largeScreen} />}
          </>
        </CardContentWrapper>
      </CardWrapper>
      {isTooltipOpen && isProductActivated && (
        <BasicTooltip parentRef={ref}>
          {`Aktiverad licens finns under "Mina Läromedel"`}
        </BasicTooltip>
      )}
    </div>
  );
};

export default TeacherActionProductCard;
