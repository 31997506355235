import React from 'react';
import { MenuItem, Menu, MenuTriggerButtonMore } from '@gleerups/syntax4';
import { useDispatch } from 'react-redux';
import {
  setActivateLicenseModalId,
  setShareModalEducationalMaterialIdOrProductId,
} from '../productsReducer';
import { LicenseType, Product } from '../types';
import LicenseStatus from '../licenseStatus';
import licenseExpirationInfo from './licenseExpirationInfo';

type CardMenuProps = {
  product: Product;
  removeProduct: () => void;
};

const CardMenu = ({ product, removeProduct }: CardMenuProps) => {
  const dispatch = useDispatch();

  const { type, status, expires } = product.license;
  const expired = status === LicenseStatus.EXPIRED;
  const { expiresSoon } = licenseExpirationInfo(expires, type);

  const menuItems: MenuItem[] = [
    {
      key: 'activate ',
      label: expiresSoon ? 'Aktivera ny lärarlicens' : 'Aktivera lärarlicens',
      kind: 'text',
      onClick: () =>
        dispatch(setActivateLicenseModalId(product.educationalMaterialId || product.id)),
    },
  ];

  if (expired) {
    menuItems.push({
      key: 'remove',
      label: 'Ta bort',
      kind: 'text',
      onClick: () => removeProduct(),
    });
  } else {
    menuItems.push({
      key: 'tip',
      label: 'Tipsa en kollega',
      kind: 'text',
      onClick: () =>
        dispatch(
          setShareModalEducationalMaterialIdOrProductId(product.educationalMaterialId || product.id)
        ),
    });
  }

  // Not allowed to activate license
  if (!((expired || type === LicenseType.Trial || expiresSoon) && product.availableLicenses)) {
    menuItems.splice(0, 1);
  }

  return (
    <Menu
      triggerButton={MenuTriggerButtonMore}
      items={menuItems}
      heading={`${type === LicenseType.Trial ? 'Testlicens' : 'Lärarlicens'}`}
      subheading={`${expired ? 'Gick ut den' : 'Giltig till'} ${expires.split('T')[0]}`}
    />
  );
};

export default CardMenu;
