import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@gleerups/auth';
import { isTeacher, isStudentTrial } from '@gleerups/auth-core';
import { IllustratedMessage } from '@gleerups/syntax4';
import styled from 'styled-components';
import { RoutePaths } from '../../routePaths';

const TextStyledButton = styled.button`
  font-size: 1em;
  text-align: left;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  color: var(--hue-french-blue);
  text-decoration: none;
  margin-bottom: 0.75rem;
  transition: color ease-in-out 0.15s;
  font-weight: 600;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    color: var(--hue-yale-blue);
  }
`;

type NoLicensedResultsViewProps = {
  isSkolon: boolean;
};

const NoLicensedResultsView = ({ isSkolon }: NoLicensedResultsViewProps) => {
  const { user } = useAuth();

  const openHelpModal = window.gleerupsHeader?.openHelpModal;

  const NoResultMessage = () => (
    <>
      <p>
        Under fliken <Link to={RoutePaths.ACTIVATE_LICENSE}>Skolans läromedel</Link> hittar du
        tillgängliga lärarlicenser att aktivera. Elevernas läromedelslicenser aktiverar du under{' '}
        <a href={`${window.location.origin}/lad/?p=0`}>Administration</a>.
      </p>

      <p>
        Är du intresserad av fler läromedel kan du prova valfria digitala läromedel gratis i 30
        dagar under fliken <Link to={RoutePaths.TRIAL}>Prova gratis</Link>.
      </p>

      <br />

      <p>
        Mer hjälp hittar du i{' '}
        <TextStyledButton
          onClick={() => {
            if (openHelpModal) {
              openHelpModal();
            }
          }}
        >
          Hjälp-menyn
        </TextStyledButton>
        .
      </p>
    </>
  );

  const NoResultSkolonMessage = () => (
    <p>
      Du aktiverar dina läromedel i{' '}
      <a href="https://skolon.com/se/" target="_blank" rel="noreferrer">
        Skolon
      </a>{' '}
      där du också tilldelar eleverna deras licenser.
      <br />
      Är du intresserad av fler läromedel kan du från Skolon prova valfria digitala läromedel i 30
      dagar.
    </p>
  );

  if (isStudentTrial(user)) {
    return (
      <IllustratedMessage
        illustrationName="empty"
        heading="Här var det tomt på läromedel"
        body={
          <p>
            Klicka på{' '}
            <Link to={RoutePaths.TRIAL} href={RoutePaths.TRIAL} className="no-results-view-link">
              Prova gratis
            </Link>{' '}
            och välj vilket läromedel du vill prova.
          </p>
        }
      />
    );
  }

  if (isTeacher(user)) {
    return (
      <IllustratedMessage
        illustrationName="empty"
        heading="Inga aktiverade läromedel än"
        body={!isSkolon ? <NoResultMessage /> : <NoResultSkolonMessage />}
      />
    );
  }

  return (
    <IllustratedMessage
      illustrationName="empty"
      heading="Oj! Här var det tomt på läromedel"
      body="Be din lärare eller kursansvarig om hjälp för att få tillgång till läromedel."
    />
  );
};

export default NoLicensedResultsView;
