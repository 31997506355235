// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ApiFetchError, apiClient } from '@gleerups/api-fetch';
import { reportError } from '@gleerups/error-reporter';
import formatMaterials from './formatMaterials';
import { Product } from '../Products/types';
import { validate } from 'uuid';

const onError = ({ statusCode, error }: { statusCode: number; error: ApiFetchError }) => {
  if (statusCode >= 500) {
    reportError(error, { component: 'library-api' });
  }
};

const coreApi = apiClient({
  apiUrl: '/core_v2/api',
  onError,
});

const apiBackend = apiClient({
  apiUrl: `/api`,
  onError,
});

const api = {
  async fetchProducts(filter: string, flushCache = false): Promise<Product[]> {
    return formatMaterials(
      await apiBackend(`/products?filter=${filter}&flushCache=${flushCache}`, {
        // This is intentional, if contex thinks we are logged in
        // but core has a different opinion, this will redirect us to login
        // If contex doesn't think we are logged in the request will be fine.
        ignoreRedirect: false,
      })
    );
  },
  async fetchPublicTrialProducts(): Promise<Product[]> {
    return formatMaterials(
      await apiBackend('/public/products', {
        // This is intentional, if contex thinks we are logged in
        // but core has a different opinion, this will redirect us to login
        // If contex doesn't think we are logged in the request will be fine.
        ignoreRedirect: false,
      })
    );
  },

  async getIsSkolonCustomer(customerId: string) {
    // API backend receives lots of errors since schoolUnitCode is "0"
    // => add some logging for this
    if (!parseInt(customerId, 10)) {
      console.error('Illegal customerId', customerId);
      // Do not spam sentry, keep 2% of the events
      if (Math.random() < 0.02) {
        reportError(Error('Illegal customerId'), { customerId });
      }
      return false;
    }

    try {
      const response = await apiBackend(`/public/skolon/iscustomer/${customerId}?type=customerId`, {
        method: 'GET',
      });
      return response.isSkolonCustomer;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reportError(e, { customerId });
      return false;
    }
  },

  async fetchProduct(id: string) {
    return apiBackend(`/public/products/${id}`, {
      ignoreRedirect: true,
    });
  },

  async flushProductCache() {
    return apiBackend('/products/cache', {
      method: 'DELETE',
    });
  },

  async fetchEducationalStages() {
    return apiBackend('/public/educational-stages', {
      ignoreRedirect: true,
    });
  },

  async activateTrialLicense(educationalMaterialIdOrProductId: string) {
    const param = validate(educationalMaterialIdOrProductId) ? 'uuidv4' : 'productId';
    const response = await coreApi(
      `/v2/license/FreeTrial/Create?${param}=${educationalMaterialIdOrProductId}`
    );
    return response;
  },

  async fetchAvailableLicenses(educationalMaterialIdOrProductId: string) {
    const param = validate(educationalMaterialIdOrProductId) ? 'uuidv4' : 'productId';
    const response = await coreApi(
      `/v2/user/Customer/Product/AvailableLicenses?${param}=${educationalMaterialIdOrProductId}`
    );
    return response;
  },

  async activateLicense(licenseId: string) {
    const response = await coreApi(`/v2/user/AddLicense?licenseId=${licenseId}`);
    return response;
  },

  async removeExpiredLicense(id: string) {
    const response = await coreApi(`/user.php?method=removeExpiredLicense&licenseId=${id}`, {
      method: 'POST',
    });
    return response;
  },

  async fetchAlerts() {
    return apiBackend('/user/alerts', {
      ignoreRedirect: true,
    });
  },

  async markAlertAsRead(alertId: string) {
    return apiBackend(`/user/alerts/${alertId}`, {
      method: 'DELETE',
    });
  },
};

export default api;
